import React, { FC, Ref, useEffect, SetStateAction } from 'react'
import {
    AccountBalance,
    CardMembershipOutlined,
    Person,
} from '@mui/icons-material'
import {
    Box,
    Checkbox,
    Divider,
    FormControlLabel,
    Grid,
    Typography,
} from '@mui/material'
import IconText from 'components/IconText'
import Input from 'components/Input'
import FormSubtitle from 'components/FormSubtitle'
import { useFormikContext } from 'formik'
import RideToWorkLogo from 'assets/ride-to-work-form-logo.svg'
import {
    IRideToWorkCertificate,
    IT4UCertificateData,
} from 'Interfaces/Certificate'
import { ICompanyData } from 'Interfaces/Company'
import { useMutation } from 'react-query'
import { getCertificateCalculation, verifyAddress } from 'api/public'
import Button from 'components/Button'
import ErrorMessage from 'components/ErrorMessage'
import { TITLE_OPTIONS } from 'utils/constants'
import LoqateSearch from 'components/Loqate/LoqateSearch'
import CustomTooltip from 'components/CustomTooltip'
import ReCAPTCHA from 'react-google-recaptcha'
import UnverifiedAddressModal from './UnverifiedAddressModal'
import LoadingSpinner from 'components/LoadingSpinner'
import { convertToPounds } from 'utils/convertToPounds'
import _ from 'lodash'

interface IProps {
    resetToStep0: () => void
    companyData: ICompanyData
    error: string
    isLoading: boolean
    isT4U: boolean
    T4UCertificateData: IT4UCertificateData
    recaptchaRef: Ref<ReCAPTCHA>
    t4uIsDataFetching: boolean
    addressErrorModal: boolean
    setAddressErrorModal: React.Dispatch<SetStateAction<boolean>>
    pushDataLayerEvent: (eventName: string, title: string) => void
}

const Step2: FC<IProps> = ({
    resetToStep0,
    companyData,
    error,
    isLoading,
    isT4U,
    T4UCertificateData,
    recaptchaRef,
    t4uIsDataFetching,
    addressErrorModal,
    setAddressErrorModal,
    pushDataLayerEvent,
}) => {
    const formik = useFormikContext<IRideToWorkCertificate>()

    if (formik.errors && !formik.isSubmitting) {
        const { employeeForm, ...rest } = formik.errors
        let employeeFormErrors
        let errorMessage

        if (employeeForm) {
            employeeFormErrors = Object.keys(employeeForm).map(
                (element: string) => `employeeForm.${element}`
            )
            errorMessage = [...employeeFormErrors]
        }

        errorMessage?.concat(Object.keys(rest))

        if (Array.isArray(errorMessage)) {
            const element = document.querySelector(
                `input[name='${errorMessage[0]}']`
            ) as Element
            element?.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
    }

    const { certificateAmount } = formik.values
    const { certificateAmount: certificateAmountError } = formik.errors
    const {
        id,
        minCertificateAmount,
        maxCertificateAmount,
        navisionNumber,
        companyName,
        descriptiveText,
    } = companyData

    const shouldFetchData =
        (certificateAmount as number) >= minCertificateAmount &&
        (certificateAmount as number) <= maxCertificateAmount &&
        !isT4U

    const { mutate, data: response } = useMutation(() =>
        getCertificateCalculation(id, certificateAmount as number)
    )

    const showCalculations = Boolean(
        certificateAmount && !certificateAmountError
    )

    const {
        totalSalarySacrificeAmount,
        grossSalarySacrificeAmount,
        numberOfSacrifices,
    } = response?.data || {}

    useEffect(() => {
        if (shouldFetchData) {
            mutate()
        }
    }, [certificateAmount])

    const address = {
        Address1: formik.values.employeeForm.addressLine1,
        Address2: formik.values.employeeForm.addressLine2,
        City: formik.values.employeeForm.companyTown,
        ProvinceName: formik.values.employeeForm.companyCounty,
        PostalCode: formik.values.employeeForm.companyPostcode,
        Country: 'GB',
    }

    function setAllFieldsAsTouched() {
        const { employeeForm, ...rest } = formik.values
        const updatedEmployeeForm = _.mapValues(
            formik.values.employeeForm,
            (value) => (value === null ? '' : value)
        )
        formik.setFieldValue('employeeForm', updatedEmployeeForm)
        Object.keys(rest).forEach((element: string) => {
            formik.setFieldTouched(element, true)
        })
        Object.keys(employeeForm).forEach((element: string) => {
            formik.setFieldTouched(`employeeForm.${element}`, true)
        })
    }

    function addressIsNotEmpty() {
        const { addressLine1, companyPostcode, companyTown } =
            formik.values.employeeForm

        return Boolean(addressLine1 && companyPostcode && companyTown)
    }

    const handleSubmit = async () => {
        setAllFieldsAsTouched()

        if (addressIsNotEmpty()) {
            const res = await verifyAddress(address)
            const addressQuality = res.data?.[0]?.Matches?.[0]?.AQI
            if (!['A', 'B'].includes(addressQuality)) {
                setAddressErrorModal(true)
            } else {
                formik.handleSubmit()
            }
        }
    }

    function handleNotYourEmployer() {
        pushDataLayerEvent('notYourEmployer', 'Personal Details')
        resetToStep0()
    }

    if (t4uIsDataFetching) {
        return <LoadingSpinner />
    }

    function generateSalarySacrificeAmountText(): string {
        const { salarySacrificeDeductionFrequency } = companyData
        let frequencyText = ''

        switch (salarySacrificeDeductionFrequency) {
            case 'MONTHLY':
                frequencyText = 'Monthly'
                break
            case 'WEEKLY':
                frequencyText = 'Weekly'
                break
            case 'FORTNIGHTLY':
                frequencyText = 'Fortnightly'
                break
            case 'FOURWEEKLY':
                frequencyText = 'Four weekly'
                break
            default:
                break
        }

        if (frequencyText) {
            return `Gross ${frequencyText} salary sacrifice amount: `
        }

        return ''
    }

    function generateSalarySacrificeTermsText(termsValue: number): string {
        const { salarySacrificeDeductionFrequency } = companyData
        let frequencyText = ''

        switch (salarySacrificeDeductionFrequency) {
            case 'MONTHLY':
                frequencyText = 'months'
                break
            case 'WEEKLY':
                frequencyText = 'weeks'
                break
            case 'FORTNIGHTLY':
                frequencyText = 'fortnights'
                break
            case 'FOURWEEKLY':
                frequencyText = 'four weeks'
                break
            default:
                break
        }

        if (frequencyText) {
            return `Salary sacrifice repayment term: ${termsValue} ${frequencyText}`
        }

        return ''
    }

    const salarySacrificeAmountText = generateSalarySacrificeAmountText()

    return (
        <>
            <UnverifiedAddressModal
                isOpen={addressErrorModal && formik.isValid}
                close={() => setAddressErrorModal(false)}
                address={address}
                submit={() => formik.handleSubmit()}
            />
            {error && <ErrorMessage text={error} />}
            <Typography
                variant="h5"
                sx={{ color: 'primary.main', borderBottom: '3px solid' }}
            >
                Employer details
            </Typography>
            <Grid
                container
                columnSpacing={{ xs: 4, md: 8 }}
                spacing={4}
                paddingX="1rem"
                mb={1}
            >
                <Grid item xs={12} md={6} display="grid" gap="1rem">
                    {isT4U && (
                        <IconText
                            text={`Certificate number: ${T4UCertificateData.certificateNumber}  `}
                        >
                            <CardMembershipOutlined
                                sx={{ color: 'primary.main' }}
                            />
                        </IconText>
                    )}

                    <IconText
                        text={`Account number: ${
                            navisionNumber || T4UCertificateData.accountNumber
                        }  `}
                    >
                        <Person sx={{ color: 'primary.main' }} />
                    </IconText>

                    <IconText
                        text={`Employer: ${
                            companyName || T4UCertificateData.employer
                        }`}
                    >
                        <AccountBalance sx={{ color: 'primary.main' }} />
                    </IconText>
                    {!isT4U && (
                        <Box display="flex" gap=".3rem">
                            <Typography>Not your employer? - </Typography>

                            <Typography
                                sx={{
                                    cursor: 'pointer',
                                    textDecoration: 'underline',
                                }}
                                onClick={handleNotYourEmployer}
                                color="primary"
                            >
                                click here
                            </Typography>
                        </Box>
                    )}
                </Grid>
                <Grid item xs={12} md={6}>
                    {companyData.logoURL ? (
                        <img
                            style={{ maxWidth: '150px', maxHeight: '150px' }}
                            src={companyData.logoURL}
                        />
                    ) : (
                        <img width="250px" height="55px" src={RideToWorkLogo} />
                    )}
                    <Typography
                        sx={{ wordWrap: 'break-word', maxWidth: '65ch' }}
                        mt={1}
                    >
                        {descriptiveText}
                    </Typography>
                </Grid>
            </Grid>
            <FormSubtitle text="Personal details" />

            <Grid
                container
                columnSpacing={{ xs: 4, md: 8 }}
                spacing={4}
                justifyContent="space-between"
                mb={1}
            >
                <Grid item xs={12} md={6}>
                    <Input
                        required
                        select
                        fullWidth
                        maxWidth="600px"
                        name="employeeForm.title"
                        label="Title"
                        options={TITLE_OPTIONS}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <Input
                        required
                        label="First name"
                        name="employeeForm.firstName"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Input
                        required
                        label="Last name"
                        name="employeeForm.lastName"
                        fullWidth
                    />
                </Grid>
                {!isT4U && (
                    <Grid item xs={12} md={6}>
                        <Input
                            name="dateOfBirth"
                            type="date"
                            label="Date of Birth"
                            fullWidth
                            required
                        />
                    </Grid>
                )}
                {companyData.requirePayrollNumber && (
                    <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{
                            display: 'flex',
                            gap: '1rem',
                            alignItems: 'center',
                        }}
                    >
                        <Input
                            name="payrollEmployeeNumber"
                            label="Employee Payroll Number"
                            fullWidth
                            required
                        />
                        <CustomTooltip text="Your unique payroll number - contact your scheme administrator" />
                    </Grid>
                )}
            </Grid>
            <FormSubtitle text="Your Contacts" />

            <Grid container spacing={4} columnSpacing={{ xs: 4, md: 8 }}>
                <Grid item xs={12} md={6}>
                    <Input
                        required
                        label="Primary email address"
                        name="employeeForm.primaryEmailAddress"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Input
                        required
                        label="Confirm primary email address"
                        name="employeeForm.confirmPrimaryEmailAddress"
                        fullWidth
                        disablePasting
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Input
                        label="Secondary email address (optional)"
                        name="employeeForm.secondaryEmailAddress"
                        fullWidth
                    />
                </Grid>

                <Grid item md={6} />

                <Grid item xs={12} md={6}>
                    <Input
                        required
                        label="Primary telephone number"
                        name="employeeForm.primaryTelephoneNumber"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Input
                        label="Mobile number (optional)"
                        name="employeeForm.mobileNumber"
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12} mt={1}>
                    <FormSubtitle text="Your address" />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box>
                        <LoqateSearch
                            label="Home address Finder (required for identification)"
                            formik={formik}
                            addressFieldsNames={{
                                addressLine1: 'employeeForm.addressLine1',
                                addressLine2: 'employeeForm.addressLine2',
                                town: 'employeeForm.companyTown',
                                county: 'employeeForm.companyCounty',
                                postcode: 'employeeForm.companyPostcode',
                            }}
                        />
                    </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Input
                        name="employeeForm.addressLine2"
                        label="Home adress 2 (optional)"
                        fullWidth
                    />
                </Grid>
                <Grid xs={12} md={6} item>
                    <Input
                        required
                        name="employeeForm.companyTown"
                        label="Town"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Input
                        name="employeeForm.companyCounty"
                        label="County (optional):"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Input
                        required
                        name="employeeForm.companyPostcode"
                        label="Postcode:"
                        fullWidth
                    />
                </Grid>
            </Grid>

            {!isT4U && (
                <>
                    <FormSubtitle text="Certificate amount" />
                    <Box
                        display="flex"
                        sx={{ width: { sm: '100%', md: '50%' } }}
                    >
                        <Input
                            type="number"
                            label="Enter the total value of your bike/ or accessories:"
                            name="certificateAmount"
                            fullWidth
                            maxWidth="600px"
                            required
                            thousandSeparator
                            prefix="£"
                            tooltip={`Your company allows application between ${minCertificateAmount} and ${maxCertificateAmount}`}
                        />
                    </Box>
                    {showCalculations && (
                        <Box>
                            <Typography variant="body1">
                                Total salary sacrifice amount:{' '}
                                {convertToPounds(totalSalarySacrificeAmount)}
                            </Typography>
                            <Typography mt={1} variant="body1">
                                {salarySacrificeAmountText}
                                {convertToPounds(grossSalarySacrificeAmount)}
                            </Typography>
                            <Typography mt={1} variant="body1">
                                {generateSalarySacrificeTermsText(
                                    numberOfSacrifices
                                )}
                            </Typography>

                            <Typography mt={2} variant="body1">
                                You will receive a certificate for{' '}
                                {convertToPounds(totalSalarySacrificeAmount)}
                            </Typography>
                        </Box>
                    )}
                    <Divider />
                </>
            )}
            <FormControlLabel
                name="newsletterSignUp"
                label="Sign up for Evans Cycles Newsletter"
                control={<Checkbox />}
            />
            <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={process.env.REACT_APP_CAPTCHA_KEY ?? ''}
                onChange={(token: string | null) =>
                    formik.setFieldValue('g-recaptcha-response', token)
                }
            />
            <Button
                isLoading={isLoading}
                disabled={!formik.values['g-recaptcha-response']}
                text="Submit"
                maxWidth="500px"
                onClick={handleSubmit}
            />
        </>
    )
}

export default Step2
